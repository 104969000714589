import { auth } from './firebase';

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password);
}

// Sign In
export const doSignInWithEmailAndPassword = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password);
}

// Sign out
export const doSignOut = () => {
  return auth.signOut();
}

// Password Reset
export const doPasswordReset = (email) => {
  return auth.sendPasswordResetEmail(email, { url: window.location.origin, handleCodeInApp: true });
}

// Password Change
export const doPasswordUpdate = (password) => {
  return auth.currentUser.updatePassword(password);
}
