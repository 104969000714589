import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const prodConfig = {
  apiKey: "AIzaSyAPlJXTWTY8qfn5x-oaOAicFWSjQ7izBaY",
  authDomain: "weekyn-1.firebaseapp.com",
  projectId: "weekyn-1",
  storageBucket: "weekyn-1.appspot.com",
  messagingSenderId: "25760940113",
  appId: "1:25760940113:web:6bc266c8836226e5f07870",
  measurementId: "G-W6W5BMPP5T",
};

const devConfig = {
  apiKey: "AIzaSyAPlJXTWTY8qfn5x-oaOAicFWSjQ7izBaY",
  authDomain: "weekyn-1.firebaseapp.com",
  projectId: "weekyn-1",
  storageBucket: "weekyn-1.appspot.com",
  messagingSenderId: "25760940113",
  appId: "1:25760940113:web:6bc266c8836226e5f07870",
  measurementId: "G-W6W5BMPP5T",
};

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const db = firebase.firestore();
const settings = {
  /* your settings... */
};
db.settings(settings);

db.enablePersistence({
  synchronizeTabs: true,
});

const storage = firebase.storage().ref();

export { auth, db, storage };
